/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Vidaloka&display=swap');





// fonts
$base-font-size: 15px;
$base-font: 'DM Sans', sans-serif;
$heading-font: 'Vidaloka', serif;



// color
$dark-gray: #070143;
$body-color: #E5E5E5;//
$white: #fff;//
$light: #8188a9;
$black: #000;//
$small-black:#0D1230;//
$cyan: #848892;

$theme-primary-color:#AC8D68;//
$theme-primary-color-s2:#F7F2EE;//
$body-bg-color: #fff;//
$section-bg-color: #687693;//
$text-color: #0D0925;//
$text-color-s2: #484653;//
$text-light-color:#66717A;//
$heading-color: $dark-gray;
$border-color: #F1EAE5;//
$border-color-s2: #E0D8CE;//
$border-color-s3:  #EDE4DE;//
