/*======================================
   6. Home-style-3 
 =======================================*/

/* 6.1 wpo-service-section-s3 */
.wpo-service-section-s3 {
    padding: 120px 0 90px;

    @media(max-width:991px) {
        padding: 90px 0 60px;
    }

    @media(max-width:767px) {
        padding: 80px 0 50px;
    }

    .scervice-wrap {
        .scervice-item {
            padding: 0;
            background: transparent;
            overflow: hidden;
            margin: 0;
            margin-bottom: 30px;

            .scervice-item-img {
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: 0;
                overflow: hidden;

                img {
                    width: 100%;
                    transition: all .3s ease-in-out;
                }

                .content {
                    position: absolute;
                    left: 0;
                    bottom: -25px;
                    transition: all .3s ease-in-out;
                    opacity: 0;
                    visibility: hidden;

                    h2 {
                        margin-bottom: 0;

                        a {
                            background: $theme-primary-color;
                            text-transform: uppercase;
                            font-size: 30px;
                            color: $white;
                            padding: 10px 15px;
                        }
                    }
                }
            }


            &:hover {
                background: transparent;

                .scervice-item-img {
                    img {
                        transform: scale(1.1);
                    }

                    .content {
                        bottom: 9px;
                        opacity: 1;
                        visibility: visible;
                    }
                }

            }
        }
    }
}

/* 6.2 wpo-pricing-section */
.wpo-pricing-section,
.wpo-pricing-section-s2 {
    .wpo-pricing-wrap {
        .wpo-pricing-item {
            box-shadow: 0px 2px 16.8px 3.2px rgba(0, 38, 66, 0.08);

            @media(max-width:991px) {
                margin-bottom: 30px;
            }

            .wpo-pricing-top {
                padding: 30px;
                text-align: center;
                padding-top: 0;
                padding-bottom: 0;

                .pricing-thumb {
                    display: inline-block;
                    padding: 3px 30px 5px;
                    background: $theme-primary-color;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;

                    span {
                        text-transform: uppercase;
                        color: $white;
                    }
                }


                .wpo-pricing-text {
                    margin-top: 30px;

                    h2 {
                        color: $dark-gray;
                        font-size: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 15px;
                        font-weight: 700;

                        span {
                            font-weight: 500;
                            color: $text-color;
                            font-size: 16px;
                            text-transform: uppercase;
                        }
                    }

                    p {
                        padding-bottom: 30px;
                        max-width: 282px;
                        margin: 0 auto;
                        color: $text-color-s2;
                        border-bottom: 1px solid $theme-primary-color;
                    }
                }
            }

            .wpo-pricing-bottom {
                padding: 40px;
                padding-top: 35px;
                text-align: center;

                .wpo-pricing-bottom-text {
                    ul {
                        list-style: none;

                        li {
                            color: $text-light-color;
                            padding-bottom: 15px;
                        }
                    }

                    a {
                        font-size: 16px;
                        color: $theme-primary-color;
                        font-weight: 500;
                        display: inline-block;
                        margin-top: 20px;
                        position: relative;
                        padding: 10px 25px;
                        border: 1px solid $theme-primary-color;

                        &:hover {
                            background: $theme-primary-color;
                            color: $white;

                            &:before {
                                background-color: $theme-primary-color;
                            }
                        }
                    }
                }
            }
        }

        .col {
            &:last-child {
                .wpo-pricing-item {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/* 6.3 wpo-project-area-s2 */
.wpo-project-area-s2 {
    padding: 120px 0;

    @media (max-width:991px) {
        padding: 90px 0;
    }

    @media (max-width:767px) {
        padding: 90px 0;
    }

    .container-fluid {
        padding: 0;
    }

    .wpo-section-title {
        text-align: center;

        .shape-title {
            left: 50%;
        }
    }

    .wpo-project-wrap {
        .wpo-project-item {
            position: relative;

            .wpo-project-img {
                position: relative;
                z-index: 1;
                overflow: hidden;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: #0C1145;
                    content: "";
                    opacity: 0;
                    z-index: 1;
                    transition: all .3s;
                    visibility: hidden;
                }

                .left-border {
                    position: absolute;
                    left: 20px;
                    top: 20px;
                    width: 100%;
                    height: 100%;
                    z-index: 11;

                    &:before {
                        position: absolute;
                        left: 50%;
                        top: 15px;
                        height: 1px;
                        width: 0;
                        background: $white;
                        content: "";
                        transition: all .3s;
                        transform: translateX(-50%);
                    }

                    &::after {
                        position: absolute;
                        left: 15px;
                        top: 50%;
                        height: 0;
                        width: 1px;
                        background: $white;
                        content: "";
                        transition: all .3s;
                        transform: translateY(-50%);
                    }
                }

                .right-border {
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    width: 100%;
                    height: 100%;
                    z-index: 11;

                    &:before {
                        position: absolute;
                        right: -80%;
                        bottom: 15px;
                        height: 1px;
                        width: 0;
                        background: $white;
                        content: "";
                        transition: all .3s;
                        transform: translateX(-50%);
                    }

                    &::after {
                        position: absolute;
                        right: 15px;
                        height: 0;
                        width: 1px;
                        background: $white;
                        content: "";
                        transition: all .3s;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }

            .wpo-project-text {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                z-index: 111;
                transition: all .3s;
                opacity: 0;
                visibility: hidden;

                h2 {
                    font-size: 40px;
                    line-height: 58px;

                    @media(max-width:575px) {
                        font-size: 30px;
                        line-height: 45px;
                    }

                    a {
                        color: $white;

                        &:hover {
                            color: $theme-primary-color;
                        }
                    }
                }

                span {
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 21px;
                    color: $white;

                    @media(max-width:575px) {
                        font-size: 16px;
                    }
                }
            }

            &:hover {
                .wpo-project-img {
                    &:before {
                        opacity: .5;
                        visibility: visible;
                    }

                    .left-border {
                        &:before {
                            width: 120%;
                        }

                        &::after {
                            height: 120%;

                        }
                    }

                    .right-border {
                        &:before {
                            width: 120%;


                        }

                        &::after {
                            height: 120%;
                        }
                    }
                }

                .wpo-project-text {
                    opacity: 1;
                    visibility: visible;
                }
            }

        }

        .project-grids {
            margin: 0;

            .grid {
                width: 33.33%;
                float: left;
                padding: 0;

                @include media-query(1500px) {
                    padding: 0;
                }

                @include media-query(991px) {
                    padding: 0;
                    width: 50%;
                }

                @include media-query(767px) {
                    width: 100%;
                }

                img {
                    width: 100%;
                }

                &:nth-child(4) {
                    width: 50%;

                    @include media-query(991px) {
                        img {
                            min-height: 282px;
                            object-fit: cover;
                        }
                    }

                    @include media-query(767px) {
                        width: 100%;
                    }
                }

                &:nth-child(5) {
                    width: 50%;

                    @include media-query(991px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}