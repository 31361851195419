/*======================================
 2. Home-style-2 
 =======================================*/

/* 5.1 wpo-about-area-s2 */
.wpo-about-area-s2 {
    .wpo-about-img {
        position: relative;

        &:before {
            position: absolute;
            left: 3%;
            top: 3%;
            width: 94%;
            height: 94%;
            content: "";
            border: 1px solid rgba(255, 255, 255, .6);
        }
    }

    .wpo-about-text {
        padding-left: 80px;

        @media(max-width:1400px) {
            padding-left: 40px;
        }

        @media(max-width:1200px) {
            padding-left: 20px;
        }

        @media(max-width:991px) {
            padding-left: 0px;
            padding-top: 30px;
        }

        .wpo-about-title {
            span {
                display: inline-block;
                font-size: 18px;
                line-height: 26px;
                color: $theme-primary-color;
                padding-left: 80px;
                position: relative;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 70px;
                    height: 1px;
                    content: "";
                    background: $theme-primary-color;
                }
            }

            h2 {
                font-weight: 700;
                font-size: 45px;
                line-height: 65px;
                margin-top: 20px;
                margin-bottom: 30px;

                @media(max-width:1200px) {
                    font-size: 35px;
                    line-height: 55px;
                    margin-bottom: 20px;
                }

                @media(max-width:450px) {
                    font-size: 25px;
                    line-height: 40px;
                }
            }

        }

        h5 {
            font-style: italic;
            font-weight: normal;
            font-size: 22px;
            line-height: 32px;
            color: #4D4C60;
            font-weight: 400;
            margin-bottom: 30px;
        }

        p {
            font-size: 16px;
            color: #595959;
        }

        .btns {
            display: flex;
            align-items: center;
            margin-top: 40px;

            .theme-btn {

                &:after {
                    background: $dark-gray;
                }
            }

            ul {
                display: flex;
                align-items: center;
                list-style: none;
                padding-left: 20px;


                li {
                    .video-btn {
                        .btn-wrap {
                            display: block;
                            width: 55px;
                            height: 55px;
                            background: $white;
                            box-shadow: 0px 2px 4px 2px rgba(146, 139, 104, 0.16);
                            text-align: center;
                            line-height: 58px;
                            border-radius: 50%;
                            color: $theme-primary-color;
                            margin-right: 10px;
                            border: 0;
                        }
                    }

                    &.video-text {
                        @media(max-width:450px) {
                            display: none;
                        }

                        color: $text-color;
                        font-weight: 600;
                        width: 100%;
                        height: 100%;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

/* 5.2 wpo-service-section-s2 */
.wpo-service-section-s2,
.wpo-service-section-s3 {

    .scervice-item {
        text-align: center;
        margin: 0 15px;

        .scervice-item-img {
            margin: 0 auto;

            img {
                width: 40px;
            }
        }

        @media (max-width:767px) {
            padding: 45px 20px;
        }
    }

    .wpo-service-slider {
        .slick-slider {
            margin: 0 -15px;
            
        }

        .slick-arrow {
            position: absolute;
            transform: translateY(-50%);
            height: 50px;
            width: 50px;
            line-height: 46px;
            text-align: center;
            background: transparent;
            transition: all .3s;
            background: transparent;
            border: 1px solid $theme-primary-color;
            color: $theme-primary-color;
            text-decoration: none;
            border-radius: 50%;

            &::before {
                display: none;
            }

            &::after {
                position: absolute;
                content: "\e629";
                font-family: 'themify';
                font-size: 14px;
                text-align: center;
                left: 50%;
                transform: translateX(-50%);
            }

            @media (max-width:991px) {
                display: none;
            }

            &:hover {
                background: $theme-primary-color;
                color: #fff;
                text-decoration: none;
                border-radius: 50%;
            }
        }

        .slick-prev {
            left: -9%;
            bottom: 30%;

            @media (max-width:1599px) {
                left: -60px;
            }

            @media (max-width:1499px) {
                left: -35px;
            }

            @media (max-width:1399px) {
                left: -15px;
            }
        }

        .slick-next {
            right: -9%;
            bottom: 30%;

            @media (max-width:1599px) {
                right: -60px;
            }

            @media (max-width:1499px) {
                right: -35px;
            }

            @media (max-width:1399px) {
                right: -15px;
            }

            &::after {
                content: "\e628";
            }
        }


        .slick-dots {
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
            display: none;

            @media (max-width:991px) {
                display: block;
                bottom: -20px;
            }

            li {
                margin: 0 5px;
                border: 0;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: $theme-primary-color-s2;

                
                &.slick-active {
                    background: $theme-primary-color;
                }
                button{

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

}

/* 5.3 wpo-team-section-s2 */
.wpo-team-section-s2 {
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }


    .team-wrapper {


        .team-single {
            position: relative;
            margin-bottom: 30px;

            .team-single-img {
                overflow: hidden;
            }

            .team-single-text {
                width: 100%;
                position: unset;
                opacity: 1;
                visibility: visible;
                left: 0;
                transform: none;
            }

            &:hover {}
        }



    }

}

/* 3.4 wpo-fun-fact-section */
.wpo-fun-fact-section,
.wpo-fun-fact-section-s2 {
    text-align: center;
    background: $theme-primary-color;
    position: relative;
    z-index: 1;
    overflow: hidden;


    @include media-query(767px) {
        padding-bottom: 30px;
    }

    h2 {
        font-size: 30px;
        font-size: calc-rem-value(30);
        font-weight: 600;
        margin: 0 0 1.8em;



        @include media-query(991px) {
            font-size: 26px;
            font-size: calc-rem-value(26);
        }

        @include media-query(767px) {
            font-size: 25px;
            font-size: calc-rem-value(25);
        }
    }

    .wpo-fun-fact-grids .grid {
        width: 25%;
        float: left;

        @include media-query(767px) {
            width: 100%;
            float: none;
            margin-bottom: 50px;
        }
    }

    .grid {

        h3 {
            font-size: 65px;
            font-size: calc-rem-value(65);
            font-weight: 700;
            color: $white;
            margin: 0 0 0.2em;
            font-family: $heading-font;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-query(1200px) {
                font-size: 50px;
            }

            @include media-query(991px) {
                font-size: 40px;
                font-size: calc-rem-value(40);
            }

            @include media-query(767px) {
                font-size: 35px;
                font-size: calc-rem-value(35);
            }
        }
    }



    .grid h3+p {
        font-size: 20px;
        font-size: calc-rem-value(15);
        text-transform: uppercase;
        margin: 0;
        color: $white;
        font-family: $base-font;
        letter-spacing: 3px;

        @include media-query(991px) {
            font-size: 14px;
            font-size: calc-rem-value(14);
        }

        @include media-query(575px) {
            letter-spacing: 0;
        }
    }


    .shape-1 {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -10;

        svg {
            fill: $dark-gray;
        }
    }

    .shape-2 {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -10;

        svg {
            fill: $dark-gray;
        }
    }
}