.wpo-section-title {
	margin-bottom: 60px;
	text-align: center;
	position: relative;
	
	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span{
		text-transform: uppercase;
		color: $theme-primary-color;
		font-family: $base-font;
		font-size: 14px;
		font-weight: 700;
		line-height: 18px;
		letter-spacing: 0.2em;

	}

	h2 {
		line-height: 55px;
		margin: 0;
		margin-top: 15px;
		position: relative;
		text-transform: capitalize;
		font-family: $heading-font;
		margin-top: 0;
		font-size: 46px;
		font-weight: 400;
		line-height: 46px;
		margin-top: 20px;

		

		@include media-query(1399px) {
			font-size: 40px;
		}
		@include media-query(1199px) {
			font-size: 33px;
		}
		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}

	}

	p{
		font-size: 18px;
	}
	.shape-title{
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50% , -50%);
		img{ 
			width: 100%;
		}
		
	}

}