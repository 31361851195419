/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
    background: url(../../images/footer-bg.jpg) no-repeat center center;
    background-size: cover;
    position: relative;
    font-size: 15px;
    overflow: hidden;
    z-index: 1;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: #111;
        opacity: .9;
        z-index: -1;
    }

    ul {
        list-style: none;
    }

    p {
        color: #fff;
    }

    li {
        color: #fff;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 100px 120px;
        position: relative;
        z-index: 1;

        @media(max-width:1800px) {
            padding: 80px 50px;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 60px 30px 0;
        }
    }

    @media (max-width: 767px) {
        .wpo-upper-footer {
            padding: 30px 20px 0;
        }
    }


    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            font-weight: 600;
            font-size: 25px;
            line-height: 36px;
            color: $white;
            margin: 0;
            position: relative;
            font-family: $heading-font;

            @media (max-width: 991px) {
                font-size: 20px;
                font-size: 1.25rem;
            }
        }
    }

    .about-widget {
        .logo {
            font-size: 45px;
            font-family: $heading-font;
            color: $theme-primary-color;
            line-height: 40px;

            @media(max-width:991px) {
                font-size: 40px;
            }

            span {
                position: relative;

                i {
                    position: absolute;
                    left: 1px;
                    top: 3px;
                    font-size: 13px;
                }
            }
        }

    }

    .about-widget {
        p {
            margin-bottom: 0.8em;
            line-height: 1.9em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            overflow: hidden;
            padding-top: 10px;

            li {
                font-size: 22px;
                float: left;

                a {
                    color: $white;
                    width: 36px;
                    height: 36px;
                    line-height: 40px;
                    border: 1px solid $white;
                    display: block;
                    text-align: center;
                    @include rounded-border(50%);
                    font-size: 18px;

                    &:hover {
                        background: $white;
                        color: $theme-primary-color;
                    }
                }
            }

            li+li {
                margin-left: 25px;

                @media screen and (max-width: 1600px) {
                    margin-left: 10px;
                }

            }
        }
    }


    .link-widget {
        overflow: hidden;


        @media screen and (min-width: 1500px) {
            padding-left: 65px;
        }

        @media (max-width: 1199px) {
            padding-left: 20px;
        }

        @media (max-width: 991px) {
            padding-left: 0;
        }

        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul {
            li {
                position: relative;

                a {
                    color: #fff;
                    font-size: 17px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            li+li {
                padding-top: 15px;
            }
        }
    }


    .contact-ft {
        margin-top: 20px;
        padding-right: 60px;

        @media(max-width:1500px) {
            padding-right: 0px;
        }

        ul {
            li {
                padding-bottom: 15px;
                position: relative;
                padding-left: 35px;
                color: $white;
                font-size: 16px;

                i {
                    position: absolute;
                    left: 0;
                    top: 2px;
                }

                .fi:before {
                    font-size: 20px;
                    margin-right: 15px;
                }
            }
        }
    }



    .wpo-lower-footer {
        text-align: center;
        position: relative;
        border-top: 1px solid #5F5E5E;

        .row {
            padding: 20px 0;
            position: relative;
        }

        ul {
            display: flex;
            justify-content: space-between;

            @media (max-width: 991px) {
                flex-wrap: wrap;
                justify-content: center;
            }

            li {
                font-size: 18px;
                margin: 0;
                color: $white;

                @media (max-width: 991px) {
                    &:first-child {
                        margin-bottom: 10px;
                    }
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                }

                a {
                    color: $white;
                    text-decoration: none;

                    &:hover {
                        color: $theme-primary-color;
                    }
                }

                @media (max-width: 991px) {
                    float: none;
                    display: block;
                }
            }
        }
    }

    .instagram {
        @media(max-width:1200px) {
            margin-top: 40px;
        }

        @media(max-width:991px) {
            margin-top: 0px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;

            li {
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                padding: 0px 3px;
                margin-bottom: 5px;
                position: relative;
                transition: all .3s ease-in-out;
                &::before {
                    position: absolute;
                    left: 3px;
                    top: 0;
                    width: 97%;
                    height: 100%;
                    content: "";
                    background: red;
                    z-index: 1;
                    opacity: 0;
                    visibility: hidden;
                    transition: all .3s ease-in-out;
                }
                &:hover{
                    opacity: 0.6;
                    visibility: visible;
                }
                a {}

                img {
                    width: 100%;
                }
            }
        }
    }
}


.wpo-site-footer-s2 {
    background: #202026;

    &:before {
        display: none;
    }

    .wpo-lower-footer {
        border-top: 1px solid #2f2f38;
    }
}