/* 3.2 wpo-about-section */
.wpo-about-section {
    background: $white;

    .about-left {
        position: relative;

        @media (max-width:991px) {
            margin-bottom: 40px;
        }

        img {
            width: 100%;
        }

        .about-left-text {
            background: $theme-primary-color;
            width: 270px;
            text-align: center;
            border: 10px solid $white;
            border-left: 0;
            padding: 40px 0 23px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            @media (max-width:525px) {
                width: 200px;
                padding: 20px;
            }

            img {
                width: 150px;
                height: 150px;

                @media (max-width:525px) {
                    width: 80px;
                    height: 80px;
                }
            }

            h5 {
                font-family: $base-font;
                font-size: 18px;
                font-weight: 700;
                line-height: 76px;
                letter-spacing: 0.43em;
                color: $white;
                margin-bottom: -14px;
                text-align: center;

                @media (max-width:525px) {
                    font-size: 16px;
                    line-height: 40px;
                }
            }

            span {
                font-family: $heading-font;
                font-size: 72px;
                font-weight: 400;
                line-height: 76px;
                color: $small-black;
                margin-bottom: 0;
                text-align: center;

                @media (max-width:525px) {
                    font-size: 35px;
                    line-height: 45px;
                }
            }
        }
    }

    .about-right {

        .text {
            background: $theme-primary-color-s2;
            padding: 74px 72px;

            @media (max-width:1399px) {
                padding: 29px 40px;
            }

            @media (max-width:1199px) {
                padding: 20px 40px;
            }

            @media (max-width:991px) {
                padding: 75px 72px;
            }

            @media (max-width:525px) {
                padding: 30px 20px;
            }

            span {
                font-family: $base-font;
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;
                letter-spacing: 0.2em;
                color: $theme-primary-color;
            }

            h2 {
                font-family: $heading-font;
                font-size: 42px;
                font-weight: 400;
                line-height: 53px;
                color: $text-color;
                margin-top: 9px;
                margin-bottom: 23px;

                @media (max-width:1399px) {
                    font-size: 40px;
                }

                @media (max-width:1199px) {
                    font-size: 36px;
                    margin-bottom: 5px;
                    line-height: 40px;
                }

                @media (max-width:991px) {
                    margin-bottom: 23px;
                    font-size: 42px;
                }

                @media (max-width:767px) {
                    font-size: 36px;
                    margin-bottom: 5px;
                    line-height: 40px;
                }

                @media (max-width:525px) {
                    font-size: 25px;
                }

            }

            p {
                font-family: $base-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                color: $text-light-color;
                padding-bottom: 11px;

                @media (max-width:1199px) {
                    margin-bottom: 5px;
                    line-height: 25px;
                    padding-bottom: 5px;
                }

                @media (max-width:991px) {
                    font-size: 16px;
                    line-height: 30px;
                    padding-bottom: 11px;
                }

                @media (max-width:525px) {
                    font-size: 15px;
                    line-height: 25px;
                    padding-bottom: 11px;
                }
            }

            ul {
                margin-bottom: 20px;

                li {
                    padding: 10px 0;
                    padding-left: 40px;
                    position: relative;
                    color: $theme-primary-color;
                    list-style: none;

                    &:before {
                        position: absolute;
                        left: 0;
                        top: 10px;
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                        background: transparentize($theme-primary-color, .8);
                        content: "\e64c";
                        border-radius: 50%;
                        font-family: 'themify';
                        text-align: center;
                        color: $theme-primary-color;
                        font-size: 13px;
                    }
                }
            }



            a {
                margin-top: 10px;
            }

        }
    }
}

/* 3.3 wpo-service-section */
.wpo-service-section,
.wpo-service-section-s2,
.wpo-service-section-s3 {
    background: $small-black;
    position: relative;
    z-index: 1;
    padding: 165px 0;

    @media (max-width:991px) {
        padding-top: 90px;
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-top: 80px;
        padding-bottom: 50px;
    }


    .shape-1 {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        &::before {
            width: 108px;
            height: 100px;
            background: $theme-primary-color;
            position: absolute;
            content: "";
            top: 18%;
            left: 12%;
            transform: translate(-50%, -50%);
            animation: bounceTop 3s linear infinite;
            border-radius: 49% 51% 67% 33% / 48% 16% 84% 52%;

            @media (max-width:1199px) {
                top: 16%;
                left: 10%;
            }

            @media (max-width:425px) {
                position: unset;
            }

        }
    }


    @keyframes bounceTop {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }

        100% {
            transform: translateY(0);
        }
    }

    .wpo-section-title {

        h2 {
            color: $white;
            margin-top: 20px;
        }
    }

    .scervice-wrap {

        .scervice-item {
            background: $white;
            padding: 45px 40px;
            transition: all .4s ease-in-out;

            @media (max-width:1399px) {
                padding: 22px;
            }

            @media (max-width:1199px) {
                padding: 22px 15px;
            }

            @media (max-width:991px) {
                margin-bottom: 30px;
            }

            @media (max-width:767px) {
                padding: 45px 40px;
            }

            @media (max-width:425px) {
                padding: 25px 20px;
            }

            .scervice-item-img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: $theme-primary-color-s2;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .4s ease-in-out;

                @media (max-width:767px) {
                    margin: 0 auto;
                }

            }

            .scervice-item-text {
                @media (max-width:767px) {
                    text-align: center;
                }

                a {
                    h2 {
                        font-family: $heading-font;
                        font-size: 26px;
                        font-weight: 400;
                        line-height: 35px;
                        color: $text-color;
                        margin-top: 35px;
                        text-transform: uppercase;
                        transition: all .4s ease-in-out;

                        @media (max-width:1199px) {
                            font-size: 24px;
                            line-height: 30px;
                        }

                        @media (max-width:991px) {
                            font-size: 22px;
                        }

                        @media (max-width:767px) {
                            font-size: 20px;
                        }

                        @media (max-width:399px) {
                            font-size: 19px;
                        }

                        span {
                            color: $theme-primary-color;
                            transition: all .4s ease-in-out;
                        }

                    }
                }

                p {
                    font-family: $base-font;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 25px;
                    color: $text-light-color;
                    transition: all .4s ease-in-out;
                    margin-bottom: 0;
                }
            }

            &:hover {
                background: $theme-primary-color;

                .scervice-item-img {
                    background: $white;
                }

                .scervice-item-text {
                    a {
                        h2 {

                            color: $white;

                            span {
                                color: $white;
                            }
                        }
                    }

                    p {
                        color: $white;
                    }
                }
            }
        }
    }

}

/* 3.4 wpo-cta-section*/
.wpo-cta-section {
    margin-top: -70px;
    position: relative;
    z-index: 1;
    padding-bottom: 25px;

    @media (max-width:991px) {
        margin: 0;
        padding: 90px 0 71px;

    }

    @media (max-width:767px) {
        padding: 80px 0 41px;

    }

    .cta-wrapper {
        background: $theme-primary-color;
        padding: 45px 70px;

        @media (max-width:1199px) {
            padding: 45px;
        }

        @media (max-width:991px) {
            padding: 25px;
        }

        .cta-left {
            h2 {
                font-family: $heading-font;
                font-size: 42px;
                font-weight: 400;
                line-height: 51px;
                text-align: left;
                color: $white;
                margin-bottom: 0;

                @media (max-width:1199px) {
                    font-size: 35px;
                }

                @media (max-width:991px) {
                    font-size: 28px;
                    line-height: 40px;
                }

                @media (max-width:767px) {
                    text-align: center;
                }

                @media (max-width:440px) {
                    font-size: 21px;
                    line-height: 30px;
                }
            }
        }

        .cta-right {
            text-align: right;

            @media (max-width:767px) {
                text-align: center;
                margin-top: 20px;
            }

            a {
                background: $white;
                color: $theme-primary-color;
                transition: all .4s ease-in-out;

                &:after {
                    background: $dark-gray;
                }

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

/* 3.5 wpo-team-section */
.wpo-team-section,
.wpo-team-section-s2 {
    .team-wrapper {


        .team-single {
            position: relative;
            overflow: hidden;

            .team-single-img {
                position: relative;

                &::after {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: $text-color;
                    opacity: 0;
                    visibility: hidden;
                    transition: all .4s ease-in-out;
                }

                img {
                    width: 100%;
                    transition: all .4s ease-in-out;
                }
            }

            .team-single-text {
                text-align: center;
                background: #AC8D68;
                width: 250px;
                padding: 15px 0;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, -50%);
                transition: all .4s ease-in-out;
                opacity: 0;
                visibility: hidden;

                @media (max-width:1199px) {
                    width: 200px;
                }

                @media (max-width:480px) {
                    width: 250px;
                }

                a {
                    h2 {
                        font-family: $heading-font;
                        font-size: 25px;
                        font-weight: 400;
                        line-height: 30px;
                        color: $white;
                        margin-bottom: 3px;
                        transition: all .4s ease-in-out;
                        text-transform: capitalize;
                        display: inline-block;

                        @media (max-width:991px) {
                            font-size: 23px;
                        }

                        @media (max-width:767px) {
                            font-size: 20px;
                            line-height: 25px;
                        }

                        &:hover {
                            color: $text-color;
                        }
                    }

                }

                span {
                    font-family: $base-font;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0.06em;
                    color: $white;
                    text-transform: uppercase;
                    display: block;

                }
            }

            &:hover {
                .team-single-img {
                    &::after {
                        opacity: .3;
                        visibility: visible;
                    }

                    img {
                        transform: scale(1.1);
                    }
                }

                .team-single-text {
                    bottom: -33px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .slick-slider {
            margin: 0 -15px;
        }

        .slick-slide {
           padding: 0 15px;
        }


        /** slider controls **/
        .slick-prev,
        .slick-next {
            background-color: transparentize($theme-primary-color, 0.7);
            width: 55px;
            height: 55px;
            z-index: 10;
            border-radius: 50%;
            @include transition-time(0.5s);

            &:hover {
                background-color: transparentize($theme-primary-color, 0.4);
            }

            @include media-query(991px) {
                display: none !important;
            }
        }

        .slick-prev {
            left: -100px;

            @media (max-width:1699px) {
                left: -80px;
            }

            @media (max-width:1599px) {
                left: -60px;
            }

            @media (max-width:1499px) {
                left: -35px;
            }

            @media (max-width:1399px) {
                left: -15px;
            }

            &:before {
                font-family: "themify";
                content: "\e629";
                opacity: 1;
            }
        }

        .slick-next {
            right: -100px;

            @media (max-width:1699px) {
                right: -80px;
            }

            @media (max-width:1599px) {
                right: -60px;
            }

            @media (max-width:1499px) {
                right: -35px;
            }

            @media (max-width:1399px) {
                right: -15px;
            }

            &:before {
                font-family: "themify";
                content: "\e628";
                opacity: 1;
            }
        }


        .slick-dots {
            bottom: -30px;

            @include widther(992px) {
                display: none !important;
            }

            li {
                margin: 0;
            }

            li.slick-active {

                button {
                    background-color: $white;
                    border: 2px solid $theme-primary-color
                }
            }

            button {
                background-color: $theme-primary-color;
                width: 14px;
                height: 14px;
                border: 2px solid transparent;
                border-radius: 50%;
            }

            button:before,
            button:before {
                display: none;
            }
        }

    }
}

/* 3.6 wpo-project-section */
.wpo-project-section,
.wpo-project-area-s2 {
    background: $theme-primary-color-s2;
    position: relative;
    padding-top: 187px;
    overflow: hidden;
    z-index: 1;

    @media (max-width:991px) {
        padding-top: 82px;
    }

    @media (max-width:767px) {
        padding-top: 72px;
    }

    &::before {
        position: absolute;
        content: "";
        bottom: -250px;
        left: -210px;
        width: 1100px;
        height: 1100px;
        background: $theme-primary-color;
        border-radius: 50%;
        overflow: hidden;
        opacity: 0.1;
        z-index: -1;

        @media (max-width:1280px) {
            width: 900px;
            height: 900px;
        }

        @media (max-width:991px) {
            width: 500px;
            height: 500px;
        }

        @media (max-width:600px) {
            width: 400px;
            height: 400px;
        }
    }

    &::after {
        position: absolute;
        content: "";
        top: -250px;
        right: -280px;
        width: 1100px;
        height: 1100px;
        background: $theme-primary-color;
        border-radius: 50%;
        overflow: hidden;
        opacity: 0.1;
        z-index: -1;

        @media (max-width:1280px) {
            width: 900px;
            height: 900px;
        }

        @media (max-width:991px) {
            width: 500px;
            height: 500px;
        }

        @media (max-width:600px) {
            width: 400px;
            height: 400px;
        }
    }

    .wpo-section-title {
        text-align: left;

        @media (max-width:991px) {
            text-align: center;
        }

        .shape-title {
            left: 75px;

            @media (max-width:991px) {
                left: 50%;
            }
        }
    }

    .project-right {
        text-align: center;
        border: 1px solid $border-color-s2;
        padding-top: 15px;
        width: 230px;
        height: 82px;
        margin: 0 0 0 auto;

        @media (max-width:991px) {
            margin: 0 auto;
            width: 240px;
            height: 88px;
        }

        @media (max-width:767px) {
            width: 180px;
            height: 75px;
        }

        a {
            .theme-btn {
                padding: 20px 45px;

                @media (max-width:991px) {
                    padding: 20px 20px;
                }
            }
        }
    }

    .project-wrapper {
        margin-top: 35px;
        padding-bottom: 510px;

        @media (max-width:991px) {
            padding-bottom: 90px;
        }

        @media (max-width:767px) {
            padding-bottom: 80px;
        }

        .project-single {
            background: $white;
            transition: all .4s ease-in-out;
            padding: 35px;

            @media (max-width:1280px) {
                padding: 30px 25px;
            }

            @media (max-width:580px) {
                padding: 25px 15px;
            }

            .project-single-img {
                overflow: hidden;

                img {
                    width: 100%;
                    transition: all .4s ease-in-out;
                }
            }

            .project-single-text {
                padding-top: 35px;
                text-align: left;

                span {
                    font-family: $base-font;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 17px;
                    text-transform: uppercase;
                    letter-spacing: 0.13em;
                    color: $theme-primary-color;
                    transition: all .4s ease-in-out;
                    display: block;

                    @media (max-width:1280px) {
                        font-size: 12px;
                    }
                }

                a {
                    h2 {
                        font-family: $heading-font;
                        text-transform: capitalize;
                        font-size: 25px;
                        font-weight: 400;
                        line-height: 30px;
                        margin-bottom: 0;
                        margin-top: 9px;
                        transition: all .4s ease-in-out;
                        display: inline-block;

                        @media (max-width:1499px) {
                            font-size: 20px;
                        }


                    }
                }
            }

            &:hover {

                .project-single-img {
                    img {
                        transform: scale(1.1);
                    }
                }

                .project-single-text {
                    a {
                        h2 {
                            color: $theme-primary-color;
                        }
                    }

                }

            }
        }

        .slick-slider {
            
        }

        .slick-slide {
            padding: 0 15px;
        }
    }

}

/* 3.7 wpo-testimonial-section*/
.wpo-testimonial-section,
.wpo-testimonial-section-s2 {
    position: relative;
    z-index: 1;
    margin-top: -530px;

    @media (max-width:991px) {
        margin: 0;
        padding-bottom: 73px;
    }

    @media (max-width:767px) {
        margin: 0;
        padding-bottom: 63px;
    }

    .testimonial-wrapper {
        background: url(../../images/testimonial-bg.jpg) center/cover, no-repeat;
        width: 100%;
        position: relative;
        z-index: 1;

        @media (max-width:991px) {
            background: none;

        }

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $text-color;
            opacity: .5;
            z-index: -1;

            @media (max-width:991px) {
                display: none;

            }
        }

        .testimonial-text {
            background: $white;
            margin-top: 80px;
            text-align: center;
            padding: 80px 50px;
            padding-bottom: 180px;
            border-bottom: 10px solid $theme-primary-color;

            @media (max-width:1399px) {
                padding: 64px 40px;
                padding-bottom: 177px;
                margin-top: 48px;
            }

            @media (max-width:1199px) {
                padding: 32px 20px;
                padding-bottom: 106px;
                margin-top: 19px;
            }

            @media (max-width:991px) {
                max-width: 100%;
                height: 100%;
                padding: 30px;
                margin: 0 auto;
                background: $theme-primary-color-s2;
                padding-bottom: 80px;
            }


            img {
                margin: 0 auto;
            }

            p {
                font-family: $heading-font;
                font-size: 23px;
                font-weight: 400;
                line-height: 40px;
                color: $text-color-s2;
                padding: 0 20px;
                margin-bottom: 0;
                margin-top: 50px;

                @media (max-width:1399px) {
                    font-size: 19px;
                    padding: 0 10px;
                }

                @media (max-width:1199px) {
                    font-size: 19px;
                    padding: 0;
                }

                @media (max-width:991px) {
                    margin-top: 20px;
                }

                @media (max-width:425px) {
                    font-size: 15px;
                    line-height: 25px;
                }
            }

            h3 {
                font-family: $heading-font;
                font-size: 26px;
                font-weight: 400;
                line-height: 32px;
                color: $text-color;
                margin-top: 50px;
                position: relative;

                &::after {
                    position: absolute;
                    top: -30px;
                    left: 50%;
                    content: '';
                    background: $theme-primary-color;
                    width: 90px;
                    height: 3px;
                    transform: translate(-50%, -50%);
                }

            }

            span {
                font-family: $base-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                color: $text-light-color;
            }

        }

        .testimonial-img {
            padding-top: 100px;

            @media (max-width:991px) {
                display: none;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .testimonial-slider {

            .slick-dotted.slick-slider {
                margin-bottom: 0;
            }

            .slick-dots {
                bottom: 10%;
                left: 50%;
                transform: translate(-50%, -50%);

                @media (max-width:1199px) {
                    bottom: 5%;
                }

                @media (max-width:991px) {
                    bottom: -10px;
                    left: 50%;
                }


            }

            .slick-dots {
                display: flex;
                justify-content: center;
                margin: 0;
                padding: 1rem 0;

                list-style-type: none;

                li {
                    margin: 0 0.25rem;
                }

                button {
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    padding: 0;
                    border: none;
                    border-radius: 100%;
                    background-color: $theme-primary-color;
                    text-indent: -9999px;
                    opacity: .5;
                }

                li.slick-active button {
                    background-color: $theme-primary-color;
                    opacity: 1;
                }

            }
        }

    }

    &.wpo-testimonial-section-s2 {
        margin-top: 0;
    }
}

/* 3.8 wpo-partners-section  */
.wpo-partners-section {
    background: url(../../images/partners/partners-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: $text-color;
        z-index: -1;
        opacity: 0.9;
    }



    .hidden {
        display: none;
    }

    .partners-slider {

        .grid {
            img {
                margin: 0 auto;
                transition: all .3s ease-in-out;
            }

            &:hover {
                img {
                    filter: grayscale(100%);
                }
            }
        }

        .owl-item img {
            width: unset;
        }

        .owl-nav {
            display: none;
        }
    }
}


/* 3.9 wpo-blog-section*/
.wpo-blog-section {
    background: $border-color-s3;

    .blog-wrapper {
        .slick-slider {
            margin: 0 -15px;
        }

        .slick-slide {
           padding: 0 15px;
        }

        .blog-single {

            .images {
                position: relative;

                img {
                    width: 100%;
                }

                .date {
                    position: absolute;
                    bottom: -66px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: $theme-primary-color-s2;
                    text-align: center;
                    width: 63px;
                    height: 72px;
                    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
                    transition: all .4s ease-in-out;

                    h3 {
                        font-family: $base-font;
                        font-size: 29px;
                        font-weight: 700;
                        padding-top: 15px;
                        margin-bottom: -7px;
                        color: $small-black;

                    }

                    span {
                        font-family: $base-font;
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 23px;
                        color: $theme-primary-color;
                        transition: all .4s ease-in-out;
                    }
                }

            }

            .text {
                padding: 60px 40px 50px;
                border: 1px solid $theme-primary-color-s2;
                border-top: 0;
                transition: all .4s ease-in-out;
                text-align: left;

                @media (max-width: 1199px) {
                    padding: 60px 20px 50px;
                }

                span {
                    font-family: $base-font;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0.13em;
                    color: $text-light-color;
                    transition: all .4s ease-in-out;
                    text-transform: uppercase;
                }

                h2 {
                    font-family: $heading-font;
                    font-size: 21px;
                    font-weight: 400;
                    line-height: 32px;
                    color: $black;
                    margin-top: 10px;
                    margin-bottom: 37px;
                    transition: all .4s ease-in-out;
                }

                a {
                    color: $theme-primary-color;
                    background: $theme-primary-color-s2;
                    font-family: $base-font;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0.16em;
                    text-align: center;
                    padding: 15px 20px;
                    transition: all .4s ease-in-out;
                    border: 1px solid transparent;
                }
            }

            &:hover {
                .images {
                    .date {
                        background: $theme-primary-color-s2;
                        clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%);

                        span {
                            color: $text-color;
                        }
                    }
                }

                .text {
                    background: $theme-primary-color;
                    margin-top: 10px;
                    padding: 50px 40px 50px;
                    border: 1px solid transparent;
                    border-top: 0;

                    @media (max-width: 1199px) {
                        padding: 60px 20px 50px;
                    }

                    span {
                        color: $white;
                    }

                    h2 {
                        color: $white;
                    }

                    a {
                        background: transparent;
                        color: $white;
                        border: 1px solid $white;
                    }
                }

            }
        }

       
    }
}