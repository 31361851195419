.theme-btn {
	background: $theme-primary-color;
	color: $white;
	display: inline-block;
	font-weight: 700;
	padding: 15px 45px;
	border: 0;
	text-transform: capitalize;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	font-size: 14px;
	font-size: calc-rem-value(16);
	transition: all .3s ease-in-out;
	overflow: hidden;
	&:after {
		position: absolute;
		content: '';
		top: -50%;
		left: 0;
		width: 100%;
		height: 100%;
		background: $white;
		transition: opacity 0.5s ease-out;
		opacity: 0;
		z-index: -1;
		transition: all .3s ease-in-out;
	}

	&:hover,
	&:active {
		color: $theme-primary-color;
	}

	&:hover:after {
		opacity: 1;
		top: 0;
	}

	@include media-query(991px) {
		font-size: 18px;
		font-size: calc-rem-value(18);
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 13px;
	}
}



.view-cart-btn {
	@extend .theme-btn;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	padding: 10px 45px;

	

	&:after{
		display: none;
	}
	&:hover{
		background-color: darken($theme-primary-color, 8%);
	}

	&.s1{
		background: #ddd;

		&:hover{
			background-color: darken(#ddd, 8%);
		}
	}

}