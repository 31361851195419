@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.ttf?055dd7f306d539966d4deb00419b290b") format("truetype"),
url("../fonts/flaticon.woff?055dd7f306d539966d4deb00419b290b") format("woff"),
url("../fonts/flaticon.woff2?055dd7f306d539966d4deb00419b290b") format("woff2"),
url("../fonts/flaticon.eot?055dd7f306d539966d4deb00419b290b#iefix") format("embedded-opentype"),
url("../fonts/flaticon.svg?055dd7f306d539966d4deb00419b290b#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-email:before {
    content: "\f101";
}
.flaticon-phone-call:before {
    content: "\f102";
}
.flaticon-maps-and-flags:before {
    content: "\f103";
}
.flaticon-instagram:before {
    content: "\f104";
}
.flaticon-gallery:before {
    content: "\f105";
}
.flaticon-edit:before {
    content: "\f106";
}
.flaticon-play:before {
    content: "\f107";
}
.flaticon-quotation:before {
    content: "\f108";
}
.flaticon-pinterest:before {
    content: "\f109";
}
.flaticon-facebook-app-symbol:before {
    content: "\f10a";
}
.flaticon-twitter:before {
    content: "\f10b";
}
.flaticon-instagram-1:before {
    content: "\f10c";
}
.flaticon-linkedin:before {
    content: "\f10d";
}
.flaticon-youtube:before {
    content: "\f10e";
}
.flaticon-search:before {
    content: "\f10f";
}
.flaticon-shopping-cart:before {
    content: "\f110";
}
.flaticon-left-arrow:before {
    content: "\f111";
}
.flaticon-user:before {
    content: "\f112";
}
.flaticon-comment-white-oval-bubble:before {
    content: "\f113";
}
.flaticon-calendar:before {
    content: "\f114";
}
.flaticon-right-arrow:before {
    content: "\f115";
}
.flaticon-left-quote:before {
    content: "\f116";
}
.flaticon-left-arrow-1:before {
    content: "\f117";
}
.flaticon-next:before {
    content: "\f118";
}
.flaticon-phone-call-1:before {
    content: "\f119";
}
.flaticon-email-1:before {
    content: "\f11a";
}
.flaticon-house:before {
    content: "\f11b";
}
.flaticon-quotation-1:before {
    content: "\f11c";
}
.flaticon-coding:before {
    content: "\f11d";
}
.flaticon-technical-support:before {
    content: "\f11e";
}
.flaticon-right-quote:before {
    content: "\f11f";
}
.flaticon-send:before {
    content: "\f120";
}
.flaticon-engineering:before {
    content: "\f121";
}
.flaticon-arrow:before {
    content: "\f122";
}
.flaticon-right-arrow-1:before {
    content: "\f123";
}
.flaticon-living-room:before {
    content: "\f124";
}
.flaticon-living-room-1:before {
    content: "\f125";
}
.flaticon-staircase:before {
    content: "\f126";
}
